import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Disc, X, Circle } from 'react-feather';
import classnames from 'classnames';
import { connect } from 'react-redux';
// import logoIcon from '../../../../assets/img/logo/logo-pay42-icon.png';
// import logoText from '../../../../assets/img/logo/logo-pay42-text.png';
import logoIpague from '../../../../assets/img/logo/ipague.png';
import api from '../../../../services/api';

const SidebarHeader = ({
  toggleSidebarMenu,
  activeTheme,
  collapsed,
  toggle,
  sidebarVisibility,
  menuShadow,
  user
}) => {
  const [clientLogo, setClientLogo] = useState();
  function _imageEncode(arrayBuffer) {
    // eslint-disable-next-line no-undef
    const b64encoded = btoa(
      [].reduce.call(
        new Uint8Array(arrayBuffer),
        // eslint-disable-next-line func-names
        function(p, c) {
          return p + String.fromCharCode(c);
        },
        ''
      )
    );
    const mimetype = 'image/jpeg';
    return `data:${mimetype};base64,${b64encoded}`;
  }

  const getUserLogo = async () => {
    // const respLogo = await api.get('/clients/logo', {
    //   responseType: 'arraybuffer'
    // });
    // setClientLogo(_imageEncode(respLogo.data));
  };

  const homeURL = () => {
    const { userRole } = user;

    let url = '';
    switch (userRole) {
      case 'company':
        url = 'company/atividade';
        break;
      case 'client':
        url = 'clients/home';
        break;
      case 'admin':
        url = 'admin/home';
        break;
      case 'hms':
        url = 'hms/';
        break;

      default:
        url = 'home/';
    }

    return `/plataforma/${url}`;
  };

  useEffect(() => {
    if (['client', 'company'].includes(user.userRole)) getUserLogo();
  }, []);

  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item mr-auto">
          <NavLink
            to={homeURL}
            className="navbar-brand"
            style={{
              marginTop: 0,
              marginRight: 0
            }}
          >
            {clientLogo ? (
              <img
                src={clientLogo}
                alt="logo"
                style={{ maxHeight: '60px', marginLeft: '15px' }}
              />
            ) : (
              // <>
              //   <img
              //     src={logoIcon}
              //     alt=""
              //     style={{ maxHeight: '30px', marginLeft: '-3px' }}
              //   />
              //   <img
              //     src={logoText}
              //     alt=""
              //     style={{ maxHeight: '30px', marginLeft: '15px' }}
              //   />

              // </>
              <img
                src={logoIpague}
                alt=""
                style={{
                  maxHeight: '55px',
                  marginLeft: '15px'
                }}
              />
            )}
          </NavLink>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle">
            {collapsed === false ? (
              <Disc
                onClick={() => {
                  toggleSidebarMenu(true);
                  toggle();
                }}
                className={classnames(
                  'toggle-icon icon-x d-none d-xl-block font-medium-4',
                  {
                    'text-primary': activeTheme === 'primary',
                    'text-success': activeTheme === 'success',
                    'text-danger': activeTheme === 'danger',
                    'text-info': activeTheme === 'info',
                    'text-warning': activeTheme === 'warning',
                    'text-dark': activeTheme === 'dark'
                  }
                )}
                size={20}
                data-tour="toggle-icon"
                style={{ marginTop: '3px' }}
              />
            ) : (
              <Circle
                onClick={() => {
                  toggleSidebarMenu(false);
                  toggle();
                }}
                className={classnames(
                  'toggle-icon icon-x d-none d-xl-block font-medium-4',
                  {
                    'text-primary': activeTheme === 'primary',
                    'text-success': activeTheme === 'success',
                    'text-danger': activeTheme === 'danger',
                    'text-info': activeTheme === 'info',
                    'text-warning': activeTheme === 'warning',
                    'text-dark': activeTheme === 'dark'
                  }
                )}
                size={20}
              />
            )}
            <X
              onClick={sidebarVisibility}
              className={classnames(
                'toggle-icon icon-x d-block d-xl-none font-medium-4',
                {
                  'text-primary': activeTheme === 'primary',
                  'text-success': activeTheme === 'success',
                  'text-danger': activeTheme === 'danger',
                  'text-info': activeTheme === 'info',
                  'text-warning': activeTheme === 'warning',
                  'text-dark': activeTheme === 'dark'
                }
              )}
              size={20}
            />
          </div>
        </li>
      </ul>
      <div
        className={classnames('shadow-bottom', {
          'd-none': menuShadow === false
        })}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps)(SidebarHeader);
