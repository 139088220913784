import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import classnames from 'classnames';
import { ChevronRight } from 'react-feather';

class SideMenuGroup extends React.Component {
  constructor(props) {
    super(props);
    this.flag = true;
    this.parentArray = [];
    this.childObj = {};
  }

  state = {
    // isOpen: false,
    // activeItem: this.props.activePath,
  };

  componentDidUpdate(prevProps, prevState) {
    const { activePath, collapsedMenuPaths, parentArr } = this.props;
    if (prevProps.activePath !== activePath) {
      if (this.childObj.navLink && this.childObj.collapsed) {
        collapsedMenuPaths(this.childObj.navLink);
      }
      if (activePath === this.childObj.navLink && !parentArr.includes(this.parentArray[0])) {
        parentArr.splice(0, parentArr.length);
        parentArr.push(this.parentArray);
      } else if (parentArr.includes(this.parentArray)) {
        parentArr.splice(0, parentArr.length);
      }
    }
  }

  // handleActiveItem = url => {
  //   this.setState({
  //     activeItem: url,
  //   });
  // };

  renderChild(item, activeGroup, handleGroupClick, handleActiveItem, parent) {
    const {
      activeItemState,
      collapsedMenuPaths,
      parentArr,
      currentUser,
      hoverIndex,
      currentActiveGroup,
      deviceWidth,
      toggleMenu,
      handleSidebarMouseEnter,
      redirectUnauthorized,
      activePath,
    } = this.props;
    return (
      <ul className="menu-content">
        {item.children
          ? item.children.map(child => {
              const CustomAnchorTag = child.type === 'external-link' ? `a` : Link;
              if (!this.parentArray.includes(item.id) && this.flag) {
                this.parentArray.push(item.id);
              }

              if (child.navlink && child.collapsed) {
                collapsedMenuPaths(child.navLink);
              }

              if (activeItemState === child.navLink) {
                this.childObj = child;
                parentArr.push(this.parentArray);
                this.flag = false;
              }
              if (
                (child.permissions && child.permissions.includes(cp => currentUser.includes(cp))) ||
                child.permissions === undefined
              ) {
                return (
                  <li
                    key={child.id}
                    className={classnames({
                      hover: hoverIndex === child.id,
                      'has-sub': child.type === 'collapse',
                      open: child.type === 'collapse' && activeGroup.includes(child.id),
                      'sidebar-group-active': currentActiveGroup.includes(child.id),
                      active:
                        (activeItemState === child.navLink && child.type === 'item') ||
                        (item.parentOf && item.parentOf.includes(activeItemState)),
                      disabled: child.disabled,
                    })}
                    onClick={e => {
                      e.stopPropagation();
                      handleGroupClick(child.id, item.id, child.type);
                      if (child.navLink && child.navLink !== undefined) {
                        handleActiveItem(child.navLink);
                      }
                      if (deviceWidth <= 1200 && child.type === 'item') {
                        toggleMenu();
                      }
                    }}
                  >
                    <CustomAnchorTag
                      className={classnames({
                        'd-flex justify-content-between': child.type === 'collapse',
                      })}
                      to={child.navLink && child.type === 'item' ? child.navLink : ''}
                      href={child.type === 'external-link' ? child.navLink : ''}
                      onMouseEnter={() => {
                        handleSidebarMouseEnter(child.id);
                      }}
                      onMouseLeave={() => {
                        handleSidebarMouseEnter(child.id);
                      }}
                      key={child.id}
                      onClick={e => {
                        return child.type === 'collapse' ? e.preventDefault() : '';
                      }}
                      target={child.newTab ? '_blank' : undefined}
                    >
                      <div className="menu-text">
                        {child.icon}
                        <span className="menu-item menu-title">{child.title}</span>
                      </div>
                      {child.badge ? (
                        <Badge color={child.badge} className="float-right mr-2" pill>
                          {child.badgeText}
                        </Badge>
                      ) : (
                        ''
                      )}
                      {child.type === 'collapse' ? <ChevronRight className="menu-toggle-icon" size={13} /> : ''}
                    </CustomAnchorTag>

                    {child.children
                      ? this.renderChild(child, activeGroup, handleGroupClick, handleActiveItem, item.id)
                      : ''}
                  </li>
                );
              }
              if (child.navLink === activePath && !child.permissions.includes(cp => currentUser.includes(cp))) {
                return redirectUnauthorized();
              }
              return null;
            })
          : null}
      </ul>
    );
  }

  render() {
    const { group, activeGroup, handleGroupClick, handleActiveItem } = this.props;
    return <>{this.renderChild(group, activeGroup, handleGroupClick, handleActiveItem, null)}</>;
  }
}
export default SideMenuGroup;
