// const axios = require('axios');
import axios from 'axios';
import { history } from '../history';

const instance = axios.create({
  // baseURL: 'https://sandbox.pay42.com.br/dev/api',
  // 'http://ipague-sandbox-443571826.sa-east-1.elb.amazonaws.com/backoffice/api',
  // baseURL: 'http://localhost:5008/api',
  baseURL: 'https://api-backoffice.ipague.com.br',
  // timeout: 5000,
  withCredentials: true
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      history.push('/plataforma/login');
    }
    return Promise.reject(error.response);
  }
);

// module.exports = instance;
export default instance;
