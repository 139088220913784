import React from 'react';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap';

import * as Icon from 'react-feather';
import Notifications from 'components/pay42/NavbarNotifications/NavbarNotifications';

// import classnames from 'classnames';
// import Autocomplete from '../../../components/@vuexy/autoComplete/AutoCompleteComponent';
import { history } from '../../../history';
import api from '../../../services/api';

const UserDropdown = () => {
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        onClick={e => {
          e.preventDefault();
          history.push('/plataforma/user/profile');
        }}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Editar Perfil</span>
      </DropdownItem>
      {/* <DropdownItem tag="a" href="#">
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem> */}
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        onClick={async () => {
          // await api.get('/auth/logout');
          history.push('/plataforma');
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

export default function NavbarUser(props) {
  return (
    <ul className="nav navbar-nav navbar-nav-user float-right align-items-center">
      {/* <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search" />
          </NavLink> */}
      {/* <div
            className={classnames('search-input', {
              open: this.state.navbarSearch,
              'd-none': this.state.navbarSearch === false,
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary" />
            </div> */}
      {/* <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped
              placeholder="Explore Vuexy..."
              autoFocus
              clearInput={this.state.navbarSearch}
              externalClick={e => {
                this.setState({ navbarSearch: false });
              }}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay('');
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : 'X'];
                return (
                  <li
                    className={classnames('suggestion-item', {
                      active: filteredData.indexOf(item) === activeSuggestion,
                    })}
                    key={i}
                    onClick={e => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        'd-flex justify-content-between align-items-center':
                          item.file || item.img,
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={17} />
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                ? item.email
                                : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                              ? item.date
                              : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                );
              }}
              onSuggestionsShown={userInput => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput);
                }
              }}
            /> */}
      {/* <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay('');
                }}
              />
            </div> */}
      {/* </div> */}
      {/* </NavItem> */}
      <Notifications />
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">{props.userName}</span>
            <span className="user-status">Minha Conta</span>
          </div>
          <span data-tour="user">
            {/* <img src={this.props.userImg} className="round" height="40" width="40" alt="avatar" /> */}
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  );
}

// class NavbarUser extends React.PureComponent {
//   state = {
//     navbarSearch: false,
//     suggestions: []
//   };

//   componentDidMount() {
//     axios.get('/api/main-search/data').then(({ data }) => {
//       this.setState({ suggestions: data.searchResult });
//     });
//   }

//   handleNavbarSearch = () => {
//     this.setState({
//       navbarSearch: !this.state.navbarSearch
//     });
//   };

//   render() {
//     return (

//     );
//   }
// }
// export default NavbarUser;